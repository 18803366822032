<template>
  <v-app id="inspire">
    <v-alert v-model="alert.show" type="success" dismissible>
      {{ alert.message }}
    </v-alert>
    <v-navigation-drawer v-model="drawer">
    <v-list>
      <v-list-item link to="/">Home</v-list-item>
      <v-list-item link to="/about">About</v-list-item>
      <v-list-item link to="/themes">Themes</v-list-item>
      <v-list-item link to="/bookEvent">Book your show</v-list-item>
            <!--  -->
            <template v-if="isGoogleAuthenticated">
            <v-divider></v-divider>

     <v-list-item><strong>Admin</strong></v-list-item>
     <v-list-item link title="Dashboard"></v-list-item>

     <v-list-item link to="/logins"  title="Logins"></v-list-item>
      <v-list-item link to="/eventslist" title="Events"></v-list-item>
      <v-list-item link to="/manageThemes" title="Themes"></v-list-item>
      </template>
      </v-list>
      
    </v-navigation-drawer>

    <v-app-bar>
         <v-app-bar-nav-icon class="ml-6" @click="drawer = !drawer"></v-app-bar-nav-icon>

    <div>
    <router-link to="/">
       <v-img
      src="@/assets/LRD.png"
   
width="120"
      class="ml-2"
      alt="Logo"
      left
       @click="$router.push('/')"
    ></v-img>
    </router-link>
    </div>
      <v-spacer></v-spacer>
      <!--
    <template v-if="isGoogleAuthenticated">
      <span>Logged In</span>
      <v-btn icon :href="googleLogoutURL">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </template>
    <template v-else>
      
      <v-btn icon :href="googleAuthLoginURL">
        <v-icon>mdi-login</v-icon>
      </v-btn>
    </template>

    -->
     
    </v-app-bar>

    <v-main>
      <!--  -->
    <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script setup>
import { ref, reactive, provide } from 'vue';
  const alert = reactive({
    show: false,
    message: ''
  });

  const drawer = ref(null)
  // Expose the alert state to all components
  provide('alert', alert);

</script>

<script>
import { checkGoogleAuthStatus } from '@/lib/common';
import { onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';

export default {
  data() {
    return {
      isGoogleAuthenticated: false,
      googleAuthLoginURL: `${process.env.VUE_APP_GOOGLE_AUTHENTICATION_HOST}/auth/google`,
      googleLogoutURL: `${process.env.VUE_APP_GOOGLE_AUTHENTICATION_HOST}/googlelogout`,
      errorMessage: null,
    };
  },
  created() {
    //this.checkGoogleAuthStatus();
    document.title = `Living Room Disco`;
 
  },
  methods: {
    async checkGoogleAuthStatus() {
      const authStatus = await checkGoogleAuthStatus();
     
 // Use optional chaining and nullish coalescing to safely check the id
 this.isGoogleAuthenticated = (authStatus.user?.id ?? "") == "115301523157960880656";

    },
  },
  setup() {
    const route = useRoute();

    // Set the title when the component is mounted
    onMounted(() => {
      document.title = `Your Site Name - ${route.name || 'Home'}`;
    });

    // Update the title whenever the route changes
    watch(() => route.path, () => {
      document.title = `Your Site Name - ${route.name || 'Home'}`;
    });
  },
}
</script>